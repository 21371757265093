class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refreshToken;
    }

    getToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.token;
    }

    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    }

    setUser(user) {
      localStorage.setItem("user", JSON.stringify(user));
    }

    setToken(token) {
      let user = JSON.parse(localStorage.getItem("user"));
      if(user == null) {
        user = {};
      }

      // if user exists, set access token and set user
      if(user) {
        user.token = token;
        this.setUser(user);
      }
    }

    checkTokenIsExpired() {

      return this.getTokenTTL() <= 0;

      /* if (jwtDecode(token).exp < Date.now() / 1000) {
        return true;
      }
      return false; */

    }

    getTokenTTL() {
      let token = this.getToken();
      if (!token) return 0;
      const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return expiry - (Math.floor((new Date).getTime() / 1000));
    }

    getTokenExpiration() {
      let token = this.getToken();
      if(!token)
        return null;

      return (JSON.parse(atob(token.split('.')[1]))).exp;
    }

    removeUser() {
      localStorage.removeItem("user");
    }

    isLoggedIn() {
      return this.getUser() != null;
    }

    isAdmin() {
      const user = JSON.parse(localStorage.getItem("user"));
      if(user) {
        return user.is_admin;
      }

    }

    isUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      if(user) {
        return user.is_user;
      }
    }
  }

  export default new TokenService();
